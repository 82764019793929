<template>
    <div>
        <span>©2024 Copyright© ALL Rights Reserved</span>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

