<template>
    <div class="c-white">
        <h1 style="text-align: center;font-size:26px;">创建一个账号</h1>
        <div class="m-t-10 m-l-60 m-r-60" style="margin-top:5px;">
            <el-form :model="form" ref="form" :rules="rules" label-width="50px" class="form-box" label-position="top">
                <el-form-item label="账号" size="large" prop="username">
                    <el-input v-model="form.username" size="large" maxlength="20" clearable placeholder="请输入账号/手机号">
                        <template #prefix>
                            <i class="el-icon-user"></i>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="昵称" size="large" prop="nickname">
                    <el-input v-model="form.nickname" size="large" maxlength="20" clearable placeholder="请输入昵称">
                        <template #prefix>
                            <i class="el-icon-user"></i>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="验证码" size="large" prop="sms_code" v-if="config.user_regiter_sms_status == 1">
                    <el-input v-model="form.sms_code" size="large" maxlength="6" clearable placeholder="请输入验证码" style="width:50%;">
                        <template #prefix>
                            <i class="el-icon-user"></i>
                        </template>
                    </el-input>
                    <el-button @click.prevent="sendSms()" style="margin-left:10px;">{{sendText}}</el-button>
                </el-form-item>
                <el-form-item label="邀请码" size="large" prop="invite_code"  v-if="config.user_regiter_invite_code == 1">
                    <el-input v-model="form.invite_code" size="large" maxlength="20" clearable placeholder="请输入邀请码">
                        <template #prefix>
                            <i class="el-icon-user"></i>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="密码" size="large" prop="password">
                    <el-input v-model="form.password" size="large" maxlength="20" type="password" show-password clearable placeholder="请输入密码">
                        <template #prefix>
                            <i class="el-icon-lock"></i>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="确认密码" size="large" prop="ck_pass">
                    <el-input v-model="form.ck_pass" size="large" maxlength="20" type="password" show-password clearable placeholder="请确认密码">
                        <template #prefix>
                            <i class="el-icon-lock"></i>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <slot name="cut"></slot>
                </el-form-item>
                <el-form-item>
                    <el-button type="warning" size="large" style="width: 100%" @click="onSubmit('form')">注册</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>

import { login,reg,config,send } from '@/api'
export default {
    name: 'RegisterRight',
    data() {
        return {
            config:{},
            sendText:"发送短信",
            time:60,
            form: {
                username: '',
                nickname: '',
                password: '',
                client_id: '',
                invite_code:'',
                sms_code:'',
                mobileCode:1,
                type:'REGISTER',
                ck_pass: ''
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: '账号不能为空',
                        trigger: 'change'
                    }
                ],
                nickname: [
                    {
                        required: true,
                        message: '昵称不能为空',
                        trigger: 'change'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '密码不能为空',
                        trigger: 'change'
                    }
                ],
                ck_pass: [
                    {
                        required: true,
                        validator: this.validatePass2,
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    created(){
        config({}).then(res => {
            this.config = res.data
        }).catch(e => {
            
        })
    },
    methods: {
        validatePass2(rule, value, callback) {
            if (value === '') {
                callback(new Error('确认密码不能为空'))
            } else if (value !== this.form.password) {
                callback(new Error('2次输入的密码不同！请重新输入'))
            } else {
                callback()
            }
        },
        async sendSms(){
            var that = this;
            var t;
            if (this.form.username == '') {
                this.$message.error('请输入正确格式的手机号')
                return false
            } else {
                if (this.time >= 60) {
                // 模拟向后端请求验证码
                let res = await send({
                    mobile:this.form.username,
                    type:this.form.type
                });
                
                if (res.err == 0) {
                    t = setInterval(() => {
                    this.time --;
                    this.sendText = this.time + "秒"
                    if(this.time <= 0){
                        clearInterval(t)
                        this.sendText = "发送短信"
                        this.time = 60;
                    }
                    }, 1000);
                    this.$message.success(res.msg);
                    
                } else {
                    this.$message.error(res.msg);
                }
                } else {
                this.$message.error('倒计时结束后再发送');
                }
            }
        },
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.config.user_regiter_sms_status == 1){
                        if (this.form.sms_code == '') {
                            this.$message.error('请输入验证码')
                            return false
                        }
                    }

                    this.form.client_id = localStorage.getItem('client_id');
                    reg(this.form, true).then(res => {
                        this.loading = false
                        window.localStorage.setItem('token', res.data.token)
                        this.$router.push({ path: '/chat' })
                        this.$forceUpdate()
                    }).catch(e => {
                        this.loading = false
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style scoped>
.form-box :deep(.el-form-item__label) {
    color: white;
}
:deep(.el-input__prefix){
    left:10px;
}
.el-form-item{
    margin-bottom:0;
}

:deep(.el-form--label-top .el-form-item__label){
    padding-bottom:0 !important;
}
</style>
