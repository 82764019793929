<template>
  <div class="login-box flex-c flex-jc-c" style="height: 100vh">
        <div class="flex-r flex-jc-c" style="position: absolute; width: 100%">
            <div class="flex-r flex-jc-r flex-ai-c box-s brd-24">
                <div class="bg-white flex-r flex-jc-sb flex-ai-c login-bg-card">
                    <div class="image-box login-bg-card c-white text-left">
                        <div class="c-white p-40 m-t-480">
                            <div class="f-32 f-b l-h-48">
                                <span>欢迎访问~</span>
                            </div>
                            <div class="f-16 l-h-32">
                                <span>花谢花开，季节变换，云卷云舒</span>
                            </div>
                            <!-- <div class="my-button-box m-t-8">
                                <span>了解更多</span>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="model-box flex-c flex-jc-sb">
                    <div>
                        <div class="h-px-140 flex-c flex-jc-c" style="text-align: center;">
                            <div>
                                <el-image src="static/img/logo-xiao.png" style="width: 60px; height: 60px"></el-image>
                            </div>
                        </div>
                        <div class="p-20 h-px-460">
                            <SignInRight v-if="modelactive == 'model-denglu'">
                                <template v-slot:cut>
                                    <div class="f-12">
                                        <span>没有账号，准备创建一个账号么？</span><span class="model-button-a" @click="modelactive = 'model-zhuce'">立即注册</span>
                                    </div>
                                </template>
                            </SignInRight>
                            <RegisterRight v-else v-on:success="successEvent">
                                <template v-slot:cut>
                                    <div class="f-12">
                                        <span>想起来了，已经创建过账号？</span><span class="model-button-a" @click="modelactive = 'model-denglu'">直接登录</span>
                                    </div>
                                </template>
                            </RegisterRight>
                        </div>
                    </div>
                    <div class="m-b-12">
                        <div class="text-center c-white f-12">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
</template>

<script>
import { login,reg,config,send } from '@/api'
import RegisterRight from '@/components/RegisterRight'
import Footer from '@/components/Footer'
import SignInRight from '@/components/SignInRight'
export default {
  components: { RegisterRight,SignInRight,Footer },
  data () {
    return {
      isLogin:true,
      emailError: false,
      passwordError: false,
      existed: false,
      sendText:"发送短信",
      form:{
        username: '',
        password: '',
        client_id: ''
      },
      config:{},
      time:60,
      reg:{
        username: '',
        password: '',
        client_id: '',
        invite_code:'',
        sms_code:'',
        mobileCode:1,
        type:'REGISTER'
      },
      loading: false,
	  modelactive: 'model-denglu'
    }
  },
  computed: {
    checkIn () {
      return this.form.password != '' && this.form.username != '' && this.form.password.length > 5;
    },
    checkReg () {
      return this.reg.password != '' && this.reg.username != '' && this.reg.password.length > 5;
    }
  },
  created(){
    config({}).then(res => {
       this.config = res.data
      }).catch(e => {
        
      })
  },
  methods: {
    changeType() {
      this.isLogin = !this.isLogin
      this.form.username = ''
      this.form.password = ''
      this.form.client_id = ''
      this.reg.username = ''
      this.reg.password = ''
      this.reg.client_id = ''
      this.reg.invite_code = ''
      this.reg.sms_code = ''
		},
    handleLogin () {
      if (!this.checkIn) {
        this.$message.error('请输入正确格式的账号密码')
        return false
      }
      this.loading = true
      this.fetchLogin(true)
      /* uni.navigateTo({
       url:"frozen"
      }) */

    },
	successEvent() {
		this.modelactive = 'model-denglu'
	},
    fetchLogin (usePassword = false) {
      this.form.client_id = localStorage.getItem('client_id');
      console.log(usePassword)
      login(usePassword ? this.form : {
        username: '',
        password: '',
        client_id: ''
      }, usePassword).then(res => {
        this.loading = false
        window.localStorage.setItem('token', res.data.token)
        this.$router.push({ path: '/chat' })
        this.$forceUpdate()
      }).catch(e => {
        this.loading = false
      })
    },
    async sendSms(){
      var that = this;
      var t;
      if (this.reg.username == '') {
        this.$message.error('请输入正确格式的手机号')
        return false
      } else {
        if (this.time >= 60) {
          // 模拟向后端请求验证码
          let res = await send({
            mobile:this.reg.username,
            type:this.reg.type
          });
          
          if (res.err == 0) {
            t = setInterval(() => {
              this.time --;
              this.sendText = this.time + "秒"
              if(this.time <= 0){
                clearInterval(t)
                this.sendText = "发送短信"
                this.time = 60;
              }
            }, 1000);
            this.$message.success(res.msg);
              
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error('倒计时结束后再发送');
        }
      }
    },
    register(){
      if (!this.checkReg) {
        this.$message.error('请输入正确格式的账号密码')
        return false
      }
      // if (!(/^\w{1,20}$/.test(this.form.username))) {
      //   this.$message.error('密聊号只能包括下划线、数字、字母,并且不能超过20个')
      //   return;
      // }
      if (!(/^\w{1,20}$/.test(this.reg.password))) {
        this.$message.error('密码只能包括下划线、数字、字母,长度6-20位')
        return;
      }
      this.loading = true
      this.fetchRetister(true)
    },
    fetchRetister (usePassword = false) {
      this.reg.client_id = localStorage.getItem('client_id');
      console.log(usePassword)
      reg(this.reg, usePassword).then(res => {
        this.loading = false
        window.localStorage.setItem('token', res.data.token)
        this.$router.push({ path: '/chat' })
        this.$forceUpdate()
      }).catch(e => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped="scoped">
@import '../assets/login.css';
body{
  background-color: #fff;;
}
.login-register{
		height: 100%;
		box-sizing: border-box;
	}
.contain{
	width: 90%;
	height: 90%;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background-color: #fff;
	border-radius: 20px;
	box-shadow: 0 0 3px #f0f0f0,
				0 0 6px #f0f0f0;
}
.big-box{
	width: 70%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 30%;
	transform: translateX(0%);
	transition: all 1s;
}
.big-contain{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.btitle{
	font-size: 1.5em;
	font-weight: bold;
	color: rgb#007AFF;
}
.bform{
	width: 100%;
	height: 40%;
	padding: 2em 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}
.bform .errTips{
	display: block;
	width: 50%;
	text-align: left;
	color: red;
	font-size: 0.7em;
	margin-left: 1em;
}
.bform input{
	width: 50%;
	height: 45px;
	border: none;
	outline: none;
	border-radius: 10px;
	padding-left: 2em;
	background-color: #F5F5F5;
}
.bform > span{
	width: 57%;
	height: 30px;
	border: none;
	outline: none;
	border-radius: 10px;
}
.bform .btn{
	padding-left:20px;
color:#007AFF;
	cursor: pointer;
}
.bbutton{
	width: 20%;
	height: 40px;
	border-radius: 24px;
	border: none;
	outline: none;
	background-color: #007AFF;
	color: #fff;
	font-size: 0.9em;
	cursor: pointer;
}
.small-box{
	width: 30%;
	height: 100%;
	background: linear-gradient(135deg,#007AFF,#7b9fee);
	position: absolute;
	top: 0;
	left: 0;
	transform: translateX(0%);
	transition: all 1s;
	border-top-left-radius: inherit;
	border-bottom-left-radius: inherit;
}
.small-contain{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.stitle{
	font-size: 1.5em;
	font-weight: bold;
	color: #fff;
}
.scontent{
	font-size: 0.8em;
	color: #fff;
	text-align: center;
	padding: 2em 4em;
	line-height: 1.7em;
}
.sbutton{
	width: 60%;
	height: 40px;
	border-radius: 24px;
	border: 1px solid #fff;
	outline: none;
	background-color: transparent;
	color: #fff;
	font-size: 0.9em;
	cursor: pointer;
}

.big-box.active{
	left: 0;
	transition: all 0.5s;
}
.small-box.active{
	left: 100%;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: inherit;
	border-bottom-right-radius: inherit;
	transform: translateX(-100%);
	transition: all 1s;
}
.login-box {
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/hot-02.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #46464609;
}
.login-box::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(20px);
}

.login-bg-card {
    height: 760px;
    width: 800px;
    min-width: 800px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}

.image-box {
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/hot-02.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #46464609;
}

.image-box .my-button-box {
	width: 100px;
	text-align: center;
	padding: 5px 10px;
	background-color: #fff;
	color: var(--el-color-primary);
	font-weight: 600;
	border-radius: 4px;
	border: 1px solid var(--el-color-primary);
}
.image-box .my-button-box:hover {
	cursor: pointer;
	color: var(--el-color-danger);
	border: 1px solid var(--el-color-danger);
}

.model-box {
    background-color: #407bff;
    height: 760px;
    width: 500px;
    z-index: 2000;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}

.images-in {
    animation: zoomIn;
    animation-duration: 1s;
}

.images-out {
    animation: zoomOut;
    animation-duration: 1s;
}

.model-button-a:hover {
    cursor: pointer;
    color: #ffe600;
}
</style>

